module.exports = {
    name: 'BURN by Rebecca Louise',
    logo: 'https://img.genflow.com/burn/checkout/favicon.png',
    vendorUUID: process.env.GATSBY_VENDOR_UUID,
    metaData: {
        title: 'BURN by Rebecca Louise',
        description: 'The 360 home fitness transformation app',
        author: 'GENFLOW',
        url: 'https://burnbyrebecca.com',
        image: 'https://img.genflow.com/burn/checkout/favicon.png',
    },
    theme: {
        colors: {
            primary: '#9FC8D5',
            text: '#9478A3',
            ctaText: '#fff',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '',
        tagManager: null,
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://burnbyrebecca.com/terms-conditions',
        privacy: 'https://burnbyrebecca.com/privacy-policy',
        support: 'https://support.burnbyrebecca.com/hc/en-gb',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/gb/app/burn-by-rebecca-louise/id1630169893',
        android: 'https://play.google.com/store/apps/details?id=com.burnbyrebecca.app',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: 'Monthly',
            product: '1monthsale',
            // label: '20% OFF',
            // coupon: 'j7rEjqCL',
        }, {
            title: 'Quarterly',
            product: '3monthsale',
            // label: '20% OFF',
            // coupon: 'mkH6VfXe',
        }, {
            title: 'Annual',
            product: '12monthsale',
            // coupon: 'G4FZmtJQ',
            // label: '20% OFF',
        },
    ],
    offers: [
        {
            id: 'webinar',
            products: [
                {
                    title: 'Annual',
                    product: '12monthsale',
                    coupon: 'YNM0kdDW',
                    label: 'SAVE 50%',
                },
            ]
        }
    ],
    currencies: {
        available: [
            {
                code: 'GBP',
                icon: '🇬🇧',
            },
            {
                code: 'EUR',
                icon: '🇪🇺',
            },
            {
                code: 'USD',
                icon: '🇺🇸',
            },
        ],
        default: 'USD',
    },
};
