module.exports = {
    name: 'Shreddy',
    logo: 'https://img.genflow.com/shreddy/checkout/logo.png',
    vendorUUID: '8e4ca2bd-9a60-4308-bd69-1665e5009727',
    metaData: {
        title: 'Shreddy',
        description: 'SHREDDY is the only app you need to achieve your transformation goals.',
        author: 'GENFLOW',
        url: 'https://www.shreddy.com/',
        image: 'https://img.genflow.com/shreddy/checkout/logo.png',
    },
    theme: {
        colors: {
            primary: '#A5CFEA',
            text: '#697386',
            ctaText: '#FFF27C',
        },
    },
    analytics: {
        gtag: 'UA-149342411-1',
        facebookPixel: '517097062100071',
        tagManager: '',
        tapfiliate: '',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://shreddy.com/pages/terms-and-conditions',
        privacy: 'https://shreddy.com/pages/privacy-policy',
        support: 'https://support.shreddy.com/hc/en-us',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/app/shreddy/id1480771638',
        android: 'https://play.google.com/store/apps/details?id=com.bnd.shreddy',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            product: '1monthsale',
            // customStrikethroughValue: '13.99',
            // coupon: '8gYCEo3i',
            // label: '15% OFF',
        }, {
            title: '3 Months',
            product: '3monthsale',
            // customStrikethroughValue: '29.99',
            // coupon: 'FfFSMndx',
            // label: '20% OFF',
        }, {
            title: '12 Months',
            product: '12monthsalewithgift',
            // customStrikethroughValue: '89.99',
            // coupon: 'x1fL7Qlt',
            label: 'FREE GIFT *RRP £18',
        },
    ],
};
